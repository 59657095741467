import { computed, ref } from "vue";
import { defineStore } from "pinia";

export const useAccountStore = defineStore("accountStore", () => {
        
        const authenticate_data = ref();
        const verify_user = ref(false)
        return { authenticate_data,verify_user };
    },
    {
        persist: true
    });
