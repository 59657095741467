// We'll create some re-useable definitions
// because many input types are identical
// in how we want to style them.
// const textClassification = {
//     label: 'block mb-1.5 font-light text-sm formkit-invalid:text-red-500 text-dark-200 dark:text-white',
//     inner: `max-w-full flex relative`,
//     input: 'w-full h-10 px-3 border border-gray-200 formkit-invalid:border-red-500 formkit-complete:border-green-500 rounded text-sm text-dark-200 placeholder-gray-500 focus:outline-none bg-gray-100',
//     outer: 'mb-6',

import { colorpicker } from "@formkit/pro";

// };
const textClassification = {
    label: 'block mb-2 font-light text-xs formkit-errors:text-red-500 formkit-invalid:text-red-500 text-black-900 dark:text-white',
    wrapper: 'md:max-w-[22.5rem] xs:max-w-full',
    inner: `flex item-center relative h-10 rounded text-sm text-black-900 bg-gray-100 dark:bg-black-900 dark:border-0 dark:text-gray-600 focus:outline-none`,
    input: 'bg-gray-100 w-full placeholder-gray-500 placeholder:text-gray-400 rounded dark:text-white dark:placeholder:text-gray-500 formkit-errors:border formkit-errors:border-red-500 formkit-invalid:border formkit-invalid:border-red-500 focus:outline-none px-3 dark:bg-black-900 dark:rounded appearance-none placeholder:opacity-1',
    outer: 'mb-6',
    selectIcon:'w-8 h-full bg-transparent inline-flex items-center justify-center rounded',
};
const textdateClassification = {
    label: 'block mb-2 font-light text-xs formkit-invalid:text-red-500 text-black-900 dark:text-white',
    wrapper: 'max-w-[22.5rem]',
    inner: `flex item-center relative h-10 rounded text-sm text-gray-200 bg-gray-100 dark:bg-black-900 dark:border-0 dark:text-white focus:outline-none formkit-invalid:border formkit-invalid:border-red-500`,
    input: 'w-full bg-transparent placeholder:text-gray-200 dark:placeholder:text-gray-600 focus:outline-none px-3 dark:bg-black-900  dark:rounded appearance-none',
    outer: 'mb-6',
    selectIcon:'w-8 h-full bg-transparent inline-flex items-center justify-center rounded',
};
const stepClassification = {
    outer: 'mb-6',
    inner:'!border-0',
    theme:'bg-gray-100 focus:outline-none',
    label:'!text-xs',
}
const boxClassification = {
    fieldset: 'rounded-md',
    // legend: 'font-bold text-sm',
    outer:"formkit-disabled:opacity-100",
    legend: 'font-bold text-xs mb-2 font-light',
    inner: 'w-6 h-6 rounded-sm relative mr-2',
    wrapper: 'flex items-center cursor-pointer',
    help: 'mb-2',
    input: 'form-check-input appearance-none absolute data-[placeholder="true"]:text-gray-200 w-0 h-0 overflow-hidden opacity-0 -left-[999em]',
    label: 'text-sm text-black-900 dark:text-white',
    decorator: 'block w-6 h-6 rounded relative text-black-500 dark:text-white bg-gray-100 dark:bg-black-900 border border-gray-100 dark:border-black-900 formkit-invalid:border-red-500 relative',
    options:'gap-[8px_16px]',
    option: 'relative formkit-disabled:text-gray-200',
    optionLabel:'formkit-disabled:text-gray-200'
};
const buttonClassification = {
    wrapper: '',
    input: 'bg-blue-500 text-white font-normal text-sm xs:min-h-[32px] sm:h-8 rounded cursor-pointer text-center md:px-6 px-4',
    selectIcon: 'flex p-[3px] shrink-0 w-4 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
    reloadIcon: 'flex p-[3px] shrink-0 w-5 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
    copyIcon: 'flex p-[3px] shrink-0 w-5 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]', 

};
const dropdownClassification = {
    label: 'block mb-1.5 font-light text-sm formkit-invalid:text-red-500 text-black-900 dark:text-gray-300',
    inner: `max-w-full flex relative w-full h-10 border border-gray-100 rounded text-sm text-dark-200 dark:text-white bg-gray-100 dark:bg-gray-500 dark:border-0`,
    selector: `h-full w-full text-left px-3 flex items-center justify-between`,
    option: `flex items-center`,
    dropdownWrapper: `bg-white my-1 rounded-lg shadow-dropdown`,
    listbox: `bg-white p-4 rounded-lg`,
    listitem: `py-1 flex items-center cursor-pointer px-2 rounded-md hover:bg-gray-100 mb-[2px]`,
    wrapper: `mb-6 max-w-[22.5rem] `,
};
const autoCompleteClassification = {
    label: 'block mb-1.5 font-light text-sm formkit-invalid:text-red-500 text-black-900 dark:text-white formkit-errors:text-red-500',
    inner: `max-w-full flex relative w-full h-10 rounded text-sm text-black-900 dark:text-white bg-gray-100 dark:bg-black-900 dark:border-0 formkit-errors:border border-red-500`,
    input: `w-full outline-none rounded bg-gray-100 dark:bg-black-900`,
    selector: `h-full w-full text-left px-3 flex items-center justify-between`,
    option: `inline-flex items-center`,
    listboxButton: `w-8 h-full bg-gray-100 inline-flex items-center justify-center rounded`,
    // listboxButton: `w-8 h-full bg-white inline-flex items-center justify-center rounded`,
    dropdownWrapper: `bg-white my-1 rounded shadow-dropdown !max-h-[12.5rem]`,
    listbox: `bg-white p-4 rounded-lg`,
    listitem: `py-1 flex items-center cursor-pointer px-2 rounded-md hover:bg-gray-100 mb-[2px]`,
    wrapper: `mb-6 max-w-full`,
    selection: 'inline-flex items-center p-3 mr-2 mb-2 mt-2 bg-gray-100 border border-gray-100 text-black-900 text-sm rounded',
    removeSelection:'ml-[0.625rem] text-gray-300',
    prefixIcon:'p-3 bg-gray-100 dark:bg-black-900 rounded',
};
const repeaterClassification = {
    fieldset: '',
    controlLabel: 'absolute -left-[999px] hidden',
    listItem: 'h-[4.1875rem]',
    item: 'flex items-center justify-between',
    content: 'sm:w-[64%] py-2 flex justify-between',
    controls: 'grid grid-cols-2 justify-items-end w-[2.5rem] h-[1.875rem]',
    up: 'row-start-1 row-end-2 text-center ml-1 cursor-pointer inline-flex justify-center items-center',
    upControl: 'cursor-pointer',
    remove: 'col-start-1 row-start-1 row-end-3 text-center cursor-pointer self-center h-6',
    removeControl: 'cursor-pointer',
    down: 'row-start-2 row-end-3 text-center ml-1 cursor-pointer inline-flex justify-center items-center',
    downControl: 'cursor-pointer',
}
const repeaterInputClassification = {
    outer: 'mb-0',
}
const sliderClassification = {
    label: 'text-sm font-medium text-black-900 dark:text-white',
    outer: 'md:max-w-md',
    help: 'mt-0 mb-1',
    sliderInner: 'flex items-center py-1 [&>.formkit-max-value]:mb-0 [&>.formkit-max-value]:ml-8 [&>.formkit-max-value]:shrink [&>.formkit-max-value]:grow-0 [&>.formkit-icon]:bg-none [&>.formkit-icon]:border-none [&>.formkit-icon]:p-0 [&>.formkit-icon]:w-4 [&>.formkit-prefix-icon]:mr-2 [&>.formkit-suffix-icon]:ml-2 [&[data-has-mark-labels="true"]_.formkit-track]:mb-4',
    track: 'grow relative z-[3] py-1',
    trackWrapper: 'px-[2px] rounded-full bg-gray-100 dark:bg-black-900',
    trackInner: 'h-1 mx-[2px] relative',
    fill: 'h-full rounded-full absolute top-0 mx-[-4px] bg-transparent',
    marks: 'absolute pointer-events-none left-0 right-0 top-0 bottom-0',
    mark: 'absolute top-1/2 w-[3px] h-[3px] rounded-full -translate-x-1/2 -translate-y-1/2 bg-gray-400 data-[active="true"]:bg-white',
    markLabel: 'absolute top-[calc(100%+0.5em)] left-1/2 text-gray-400 text-[0.66em] -translate-x-1/2',
    handles: 'm-0 p-0 list-none',
    handle: 'group w-4 h-4 rounded-full bg-black-900 dark:bg-white absolute top-1/2 left-0 z-[2] -traglonslate-x-1/2 -translate-y-1/2 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1),0_1px_2px_0_rgba(0,0,0,0.8)] focus-visible:outline-0 focus-visible:ring-2 ring-blue-500 data-[is-target="true"]:z-[3]',
    tooltip: 'absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-[4px] bg-gray-150 dark:bg-black-900 dark:text-white text-black-900 py-1 px-2 text-xs leading-none whitespace-nowrap rounded-sm opacity-0 pointer-events-none transition-opacity after:content-[""] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-gray-150 dark:after:border-t-black-900 group-hover:opacity-100 group-focus-visible:opacity-100 group-data-[show-tooltip="true"]:opacity-100',
    linkedValues: 'flex items-start justify-between',
    minValue: 'grow max-w-[45%] mb-0 relative [&_.formkit-inner::after]:content-[""] [&_.formkit-inner::after]:absolute [&_.formkit-inner::after]:left-[105%] [&_.formkit-inner::after]:-translate-y-1/2 [&_.formkit-inner::after]:w-[10%] [&_.formkit-inner::after]:h-[1px] [&_.formkit-inner::after]:bg-gray-500',
    maxValue: 'grow max-w-[45%] mb-0 relative',
    chart: 'relative z-[2] mb-8 flex justify-between items-center w-full aspect-[6/1]',
    chartBar: 'absolute bottom-0 h-full bg-purple-500 opacity-[.66] data-[active="false"]:opacity-[.25]',
}
const toggleInputClassification = {
    label:'text-sm font-normal',
    altLabel: 'block w-full mb-1 font-bold text-sm',
    inner: '$reset inline-block mr-2 shadow-none relative',
    input: 'peer absolute opacity-0 pointer-events-none block w-full p-3 rounded-lg left-[-99999px]',
    innerLabel: 'text-[10px] font-bold absolute left-full top-1/2 -translate-x-full -translate-y-1/2 px-1',
    thumb: 'relative left-0 aspect-square rounded-full transition-all w-6 h-6 bg-dark-100 m-[2px] z-1 flex items-center justify-center shadow',
    track: 'p-0.5 min-w-[3.875rem] relative rounded-full transition-all bg-gray-100 peer-checked:bg-gray-100 overflow-hidden border-gray-100 pr-[0.3125rem] border peer-checked:[&>div:last-child]:left-full peer-checked:[&>div:last-child]:-translate-x-full peer-checked:[&>div:first-child:not(:last-child)]:left-0 peer-checked:[&>div:first-child:not(:last-child)]:translate-x-0',
    valueLabel: 'font-bold text-sm',
    wrapper: 'flex flex-wrap items-center mb-1',
}
const taglistClassification = {
    input: 'px-1 py-1 w-[0%] grow bg-transparent text-dark-100 focus:outline-none',
    removeSelection: 'w-2.5 mx-1 self-center text-black-900 leading-none',
    tag: 'flex items-center my-1 p-1 bg-gray-200 text-xs rounded-full',
    tagWrapper: 'mr-1 focus:outline-none focus:text-white [&>div]:focus:bg-blue-500 [&>div>button]:focus:text-white',
    tagLabel: 'pl-2 pr-1',
    tags: 'flex items-center flex-wrap w-full py-1.5 px-2',
    inner:'flex items-center justify-between w-full bg-white min-h-10 relative rounded max-w-full rounded text-sm text-dark-200',
    listboxButton:'border-none z-10 appearence-none p-0 items-stretch flex cursor-pointer text-sm',
    selectIcon:'w-5 grow-0 items-stretch flex shrink-0',
    dropdownWrapper: `bg-white my-1 rounded shadow-dropdown !max-h-[12.5rem]`,
    listbox: `bg-white p-4 rounded-lg`,
    listitem: `py-1 flex items-center cursor-pointer px-2 rounded-md hover:bg-gray-100 mb-[2px]`,
}

const selectClassification = {
    label: 'text-xs font-light mb-2 flex formkit-invalid:text-red-500',
    outer:'formkit-empty:text-red-500',
    inner: 'flex relative max-w-md items-center rounded mb-1 focus:outline-none ',
    input: 'w-full px-3 pr-6 h-10 text-sm rounded bg-gray-100 appearance-none placeholder:text-gray-400 dark:text-white formkit-empty:text-gray-200 text-black-900 formkit-multiple:p-0 formkit-multiple:data-[placeholder="true"]:text-inherit formkit-invalid:border formkit-invalid:border-red-500 outline-none dark:bg-black-900',
    selectIcon: 'flex p-[3px] pl-0 shrink-0 w-[13px] -ml-[24px] h-full pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700 dark:text-white py-[0.625rem] px-4'
}
// const selectClassification = {
//     label: 'text-xs font-light mb-2 flex',
//     inner: 'flex relative max-w-md items-center rounded mb-1 focus:outline-none border-gray-200 border formkit-complete:border formkit-invalid:border formkit-complete:border-green-500 formkit-invalid:border-red-500',
//     input: 'w-full py-[0.625rem] px-3 focus:outline-none text-sm rounded bg-gray-100 appearance-none text-black-900 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-400 formkit-multiple:data-[placeholder="true"]:text-inherit',
//     selectIcon: 'flex p-[3px] shrink-0 w-5 -ml-[1.5em] h-full pointer-events-none [&>svg]:w-[1em]',
//     option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-700 py-[0.625rem] px-4'
// }
const datepickerClassification = {
    label:'block text-xs font-light mb-2 text-black-900 dark:text-white',
    inner: 'relative flex bg-gray-100 px-3 py-2.5 rounded selection:bg-blue-400 formkit-invalid:border-red-500 formkit-invalid:!border dark:bg-black-900 dark:text-white',
    panelWrapper:
      'absolute top-[calc(100%_+_0.5em)] shadow-[0_0_1.25em_rgba(0,0,0,.25)] rounded-md p-5 bg-white dark:bg-black-900 z-10',
    panelHeader:
      'grid grid-cols-[2.5em_1fr_2.5em] justify-center items-center border-b-2 mb-4 pb-4',
    input: 'w-full max-w-full bg-transparent text-sm text-black-900  focus:outline-none formkit-invalid:border-0 dark:text-white',
    monthsHeader: 'flex items-center justify-center col-start-2 col-end-2',
    timeHeader: 'flex items-center justify-center col-start-2 col-end-2',
    overlayPlaceholder: 'text-gray-400',
    months: 'flex flex-wrap',
    month: `
      flex items-center justify-center
      w-[calc(33%_-_1em)] m-2 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-blue-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black-900
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
    yearsHeader: 'flex items-center justify-center col-start-2 col-end-2',
    years: 'flex flex-wrap max-w-[35em]',
    year: `
      flex items-center justify-center
      w-[calc(20%_-_1em)] m-2 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-blue-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black-900
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none
    `,
    weekDays: 'flex',
    weekDay:
      'flex w-[2.25em] h-[1em] m-1 items-center justify-center rounded-md font-medium lowercase',
    week: 'flex formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none',
    dayCell: `
      flex items-center justify-center
      w-[2.25em] h-[2.25em] m-1 p-2 rounded-md
      bg-gray-200
      aria-selected:bg-blue-500 aria-selected:text-white
      focus:outline focus:outline-2 focus:outline-blue-500 focus:outline-offset-2 focus:bg-white focus:text-black-900
      data-[is-extra=true]:opacity-25
      formkit-disabled:opacity-50 formkit-disabled:cursor-default formkit-disabled:pointer-events-none dark:text-white
    `,
    timeInput:
      'w-full border-2 border-gray-300 rounded-md p-2 my-[2em] focus-visible:outline-blue-500',
    daysHeader: 'flex items-center justify-center',
    prev: 'mr-auto px-3 py-1 hover:bg-gray-100 dark:bg-white dark:rounded-lg hover:rounded-lg col-start-1 col-end-1',
    prevLabel: 'hidden',
    prevIcon: 'flex w-3 select-none [&>svg]:w-full',
    dayButton:
      'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    monthButton:
      'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    yearButton:
      'appearance-none cursor-pointer px-3 py-1 border-2 rounded-lg mx-1 hover:border-blue-500',
    next: 'ml-auto px-3 py-1 hover:bg-gray-100 dark:bg-white dark:rounded-lg hover:rounded col-start-3 col-end-3',
    nextLabel: 'hidden',
    nextIcon: 'flex w-3 select-none [&>svg]:w-full',
    openButton: `
      appearance-none border-0 bg-transparent flex p-0 self-stretch cursor-pointer
      focus-visible:outline-none focus-visible:text-white focus-visible:bg-blue-500
    `,
    calendarIcon:
      'text-dark-200 flex w-auto grow-0 bg-gray-100 shrink-0 self-stretch select-none [&>svg]:w-full [&>svg]:m-auto [&>svg]:max-h-[1em] [&>svg]:max-w-[1em] dark:bg-black-900',
  }

  const colorPickerClassification = {

    panel: 'top[23.125rem] left[23.5625rem] absolute mt-2 z-[99] flex w-[100vw] max-w-[22.5em] touch-manipulation flex-col rounded border bg-white p-2 shadow-md group-data-[inline]:static group-data-[inline]:z-auto group-data-[inline]:w-full group-data-[inline]:shadow group-data-[inline]:group-data-[disabled]:!cursor-not-allowed group-data-[inline]:group-data-[disabled]:!pointer-events-none group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)]:w-auto group-data-[inline]:[&:has([id^=swatches]:first-child:last-child)_[id^=swatches]>div]:w-[1.5em] dark:group-data-[inline]:bg-transparent [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:!fixed [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:top-auto [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:max-w-none [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:bottom-0 [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:left-0 [@media(max-width:360px)_and_(hover:none)]:group-[&:not([data-inline])]:rounded-none flex',
    formatField: 'd-flex',
    controlGroup:'grid [grid-template-areas:"a_a_a"_"b_c_e"_"b_d_e"] mb-4 formkit-controlGroup',
    canvasHue: 'h-[2.5rem] w-[16rem] rounded',
    canvasSwatchPreview: 'w-[6.875rem] h-[1.5625rem] border border-[#f5f2f2] rounded dark:border-0',
    preview: 'w-[4.5625rem] h-[2.5rem] mr-2',
    controlHue: 'absolute !bg-white shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] -translate-y-1/2 -translate-x-1/2 pointer-events-none data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 formkit-control w-[5px] h-[51px] top-[65%] rounded',
    hexField: 'h-[2.5rem] w-[16.5rem] position: absolute; t-[13.9375rem]; l-[5.375rem] rounded bg-[#f1f5f7] py-0.3125rem pt-0.625rem !pb-0.625rem !pl-3 h-[2.5rem] !w-[21.375rem]',
    swatches: 'inline-flex flex-wrap w-full justify-self-center min-w-0 mx-auto px-[1px] pt-2 pb-2 mt-2 -mb-2 border-t border-neutral-300 overflow-auto max-h-[200px] select-none first:-mt-[3px] first:last:-mb-[3px] first:last:pb-[2px] first:pt-px first:border-t-0 dark:border-neutral-600',
    canvasAlpha: 'hidden',
    eyeDropper: 'hidden',
    fieldLabel: 'hidden',
    LS:'[grid-area:a] relative mb-4',
    canvasLS:'block w-full aspect-[2/1] cursor-pointer rounded-none',
    controlLS: 'absolute bg-white shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] -translate-y-1/2 -translate-x-1/2 pointer-events-none data-[prevent-focus-style]:shadow-[0_0_0_2px_rgba(255,255,255,1),0_0_0_3px_rgba(0,0,0,0.2)] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 w-[10px] h-[10px] rounded-full',
    canvasPreview: 'w-[4.5625rem] h-[2.5rem] rounded',
    hue:'[grid-area:c] relative inline-flex h-3/4 formkit-hue',
    // swatch: `relative text-base w-[calc((100%/10)-0.5em)] max-w-[22px] m-[0.16em] cursor-pointer before:content-[''] before:absolute before:inset-0 before:rounded before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] before:pointer-events-none before:z-[2] dark:before:shadow-[inset_0_0_0_1px_rgba(255,255,255,0.25)] data-[active=true]:after:content-[''] data-[active=true]:after:block data-[active=true]:after:absolute data-[active=true]:after:w-1.5 data-[active=true]:after:h-1.5 data-[active=true]:after:top-1/2 data-[active=true]:after:left-1/2 data-[active=true]:after:pointer-events-none data-[active=true]:after:rounded-full data-[active=true]:after:-translate-x-1/2 data-[active=true]:after:-translate-y-1/2 data-[active=true]:after:bg-white data-[active=true]:after:z-[2] data-[active=true]:after:ring-1 data-[active=true]:after:ring-[rgba(0,0,0,0.33)] [&>canvas]:block [&>canvas]:w-full [&>canvas]:aspect-[1/1] [&>canvas]:rounded [&>canvas:focus-visible]:outline-none [&>canvas:focus-visible]:ring-2 [&>canvas:focus-visible]:ring-blue-500 [&>canvas:focus-visible]:ring-offset-2 [&>canvas:focus-visible]:ring-offset-white dark:[&>canvas:focus-visible]:ring-offset-neutral-700`,
    swatchCanvas: 'p-1 rounded-lg !w-8 h-8',
    switchIcon: 'hidden',
    valueString: 'hidden',
    canvasSwatchPreviewWrapper: 'bg-[#fff] py-[0.5rem] px-[0.8125rem] w-[9.6875rem] rounded dark:bg-[#2B2C30]',
    suffixIcon: 'relative top-[-1.4375rem] left-[8.375rem] cursor-pointer',
    label:'text-xs text-black-600 font-normal mb-2 block',
}
const maskClassification = {
    label: 'block mb-2 font-light text-xs formkit-errors:text-red-500 formkit-invalid:text-red-500 text-black-900 dark:text-white',
    wrapper: 'sm:max-w-[11.4375rem] max-w-full',
    inner: `flex item-center relative h-10 rounded text-sm text-dark-200 bg-gray-100 dark:bg-black-900 dark:border-0 dark:text-white focus:outline-none`,
    input: 'w-full bg-transparent placeholder-gray-500 placeholder:text-gray-400 rounded dark:placeholder-dm-palegrey formkit-errors:border formkit-errors:border-red-500 formkit-invalid:border formkit-invalid:border-red-500 focus:outline-none px-3 dark:bg-black-900  dark:rounded appearance-none',
    outer: 'mb-6',
    selectIcon:'w-8 h-full bg-transparent inline-flex items-center justify-center rounded',
}

// }
// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
    // the global key will apply to all inputs
    global: {
        outer: 'formkit-disabled:opacity-50',
        help: 'text-xs text-gray-500',
        messages: 'list-none p-0 mt-1 mb-0',
        message: 'text-red-500 mb-1 text-xs',
    },
    button: buttonClassification,
    color: {
        label: 'block mb-1 font-bold text-sm',
        input: 'w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1',
    },
    date: textdateClassification,
    'datetime-local': textdateClassification,
    checkbox: boxClassification,
    email: textClassification,
    file: {
        label: 'block mb-1 font-bold text-sm text-black-900 dark:text-white',
        inner: 'max-w-md cursor-pointer',
        input: 'text-gray-500 text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-blue-500 file:text-white hover:file:bg-blue-600',
        noFiles: 'block text-gray-800 text-sm mb-1',
        fileItem: 'block flex text-gray-800 text-sm mb-1',
        removeFiles: 'ml-auto text-blue-500 text-sm',
    },
    month: textdateClassification,
    number: textClassification,
    password: textClassification,
    radio: {
        // if we want to override a given sectionKey
        // we can do a spread of the default value
        // along with a new definition for our target.
        ...boxClassification,
        decorator: boxClassification.decorator.replace('rounded', 'rounded-full'),
    },
    range: {
        inner: 'max-w-md',
        input: 'form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none',
    },
    search: textClassification,
    // select: textClassification,
    submit: buttonClassification,
    tel: textClassification,
    text: textClassification,
    textarea: {
        ...textClassification,
        inner: `block w-full p-3 text-sm text-gray-700 placeholder-gray-400 bg-gray-100 focus:outline-none border border-0 formkit-invalid:border-red-500 rounded outline-none relative`,
        input: `p-0 w-full outline-none bg-gray-100`,
    },
    time: textdateClassification,
    url: textClassification,
    week: textdateClassification,
    stepNumber: {
        ...stepClassification,
    },
    repeater: {
        ...repeaterClassification,
        ...repeaterInputClassification,
    },
    dropdown: {
        ...dropdownClassification,
    },
    autocomplete: {
        ...autoCompleteClassification,
    },
    slider:{
        ...sliderClassification,
    },
    toggle: {
        ...toggleInputClassification,
    },
    taglist: {
        ...taglistClassification,
    },
    select: {
        ...selectClassification
    },
    datepicker: {
        ...datepickerClassification
    },
    colorpicker: {
        ...colorPickerClassification
    },
    mask:{
        ...maskClassification
    }
};
