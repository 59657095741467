
import Axios from "@/config/AxiosConfig";

export const getLedger = async (payload) => {
    const url = `account/get-ledger/${payload.id}/${payload.type}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getMoneyIn = async () => {
    const url = `account/get-money-in-payments`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getMoneyOut = async () => {
    const url = `account/get-money-out-payments`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getFeeTypes = async () => {
    const url = `setting/fee-type/get_list`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const addFeeType = async (payload) => {
    const url = `setting/fee-type/add`;
    try {
        const { data } = await Axios.post(url,payload);
        return data;
    } catch (e) {
        return e.response;
    }
};
export const updateFeeType = async (payload) => {
    const url = `setting/fee-type/update`;
    try {
        const { data } = await Axios.post(url,payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const activateFeeType = async (id) => {
    const url = `setting/fee-type/activate/${id}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};


export const bankConnections = async () => {
    const url = `moneyhub/connections`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};


export const bankConnect = async (id) => {
    const url = `moneyhub/connect/${id}`;
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const completeAuth = async (payload) => {
    const url = `moneyhub/complete-auth-request`;
    try {
        const { data } = await Axios.post(url ,payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const pullStatements = async (date) => {
    let url = ''
    if(date)
    {
        url = `moneyhub/accounts/${date}`
    }else{
        url = `moneyhub/accounts`
    }
   
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const getUrl = async (payload) => {
    const url = `/moneyhub/get-url`
    try {
        const { data } = await Axios.post(url , payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const get = async (payload) => {
    const url = `/moneyhub/get-url`
    try {
        const { data } = await Axios.post(url , payload);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const patchData = async (payload) => {
    const url = `/moneyhub/get-patch-request-data`
    try {
        const { data } = await Axios.post(url , payload);
        return data;
    } catch (e) {
        return e.response;
    }
};
export const checkUserConnection = async () => {
    const url = `/moneyhub/check-user-connection`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const updateMoneyHubConnection = async () => {
    const url = `/moneyhub/update-moneyhub-connection`
    try {
        const { data } = await Axios.get(url);
        return data;
    } catch (e) {
        return e.response;
    }
};

export const DisconnectUsers = async () => {
    const url = `/moneyhub/delete-moneyhub-user`
    try {
        const { data } = await Axios.post(url);
        return data;
    } catch (e) {
        return e.response;
    }
};