import { createRouter, createWebHistory } from 'vue-router';

import AuthRoutes from '@/router/routes/AuthRoutes';
import SettingRoutes from '@/router/routes/SettingRoutes';
import AccountsRoutes from '@/router/routes/AccountsRoutes';
import ContactRoutes from '@/router/routes/ContactRoutes';
import { useAuthStore } from '@/stores/auth';
import { useAppStore } from '@/stores/appStore';
import { useTenantStore } from '@/stores/tenant';
import PropertyRoutes from './routes/PropertyRoutes';
// API services imports
import { getWorkSpace } from '@/api/WorkspaceServices';
import {getUrl} from '@/api/Accounts.js';
import { data } from 'autoprefixer';
// Pinia imports

const routes = [
    ...AuthRoutes,
    ...SettingRoutes,
    ...ContactRoutes,
    ...PropertyRoutes,
    ...AccountsRoutes,
    {
        path: '/dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Dashboard.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/ali-design',
        name: 'ali-design',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/AliDesign.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/flow',
        name: 'flow',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/AppVueFlow.vue'),
        meta: { isWorkspace: true },
    },
    {
        // path: '/property-board/:id/detail/:propertyId',
        path: '/diary',
        name: 'diary-page',
        query: {
            date: '',
            id: '',
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/diary/AppDiary.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/conversation',
        name: 'conversation',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/conversation/AppConversationView.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/timeline',
        name: 'timeline',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/timeline/AppTimeline.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/inbox',
        name: 'Inbox',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/settings/inbox/Inbox.vue'),
        meta: { isWorkspace: true },
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('@/views/Error404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (window.location.origin === import.meta.env.VITE_APP_DOMAIN) {
        const appStore = useAppStore();
        if (to.meta.isWorkspace) next('/');
        if (appStore.appData.isUserLoggedIn && appStore.appData.isViewd) {
            if (!to.meta.isAuth) {
                router.go(-1);
            } else {
           
                next();
            }
        } else if (to.meta.isAuth && router.currentRoute.value.query.email) {
            next();
        } else if (!to.meta.isAuth) {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const state = url.searchParams.get('state');
            const code = url.searchParams.get('code');
            const id_token = url.searchParams.get('id_token');
            if(state && code && id_token)
            {
                const {data} = await getUrl({state:state,code:code,id_token:id_token});
                if(data.get_url){
                    window.location = import.meta.env.VITE_APP_HTTP_PROTOCOL + data.get_url + "/accounts/HSBC";
                }
            }
            next();
        } else {
            next('/');
        }
    } else {
        const authStore = useAuthStore();
        const tenantStore = useTenantStore();
        if (to.meta.isWorkspace && authStore.authUser.tenant_id) {
            next();
        } else if (!to.meta.isAuth && authStore.authUser.tenant_id) {
            next('/dashboard');
        } else {
            const response = await getWorkSpace();
            if (response.status == 401) {
                // window.location.href = import.meta.env.VITE_APP_DOMAIN;
            } else {
                tenantStore.setTenant({});
                tenantStore.setTenant(response.data.tenant);
                authStore.setAuthUser(response.data.user);
                localStorage.setItem('theme', response.data.tenant?.appearance);
                if (to.meta.isAuth == undefined) next();
                else next('/dashboard');
            }
        }
    }
});

export default router;
