import { computed, ref } from "vue";
import { defineStore } from "pinia";

export const useAppStore = defineStore(
    "appStore",
    () => {
        const appData = ref({
            country: {
                name: "United Kingdom",
                label: "United Kingdom",
                value: "uk",
                nick: "UK",
                emoji: "🇬🇧",
                unicode: "U+1F1EC U+1F1E7",
                image: "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/GB.svg",
                language: "English (UK)",
                locale: "en-GB"
            },
            locale: "en-GB",
            language: "English (UK)",
            isUserLoggedIn: false,
            device_token:'',
            workplace:'',
        });

        // Get app data
        const getAppData = computed(() => appData.value);

        // Set app data
        const setAppData = (value) => {
            appData.value = value;
        };

        // Set app country
        const setAppCountry = (value) => {
            appData.value.country = value;
        };

        // Set app locale
        const setAppLocale = (value) => {
            appData.value.locale = value;
        };

        // Set app language
        const setAppLanguage = (value) => {
            console.log(value, "value");
            appData.value.language = value;
        };

        // Set logged in user
        const setLoggedInUser = (value) => {
            console.log(value, 'valueee');
            appData.value.isUserLoggedIn = value;
        };

        return {
            appData,
            getAppData,
            setAppData,
            setAppCountry,
            setAppLocale,
            setAppLanguage,
            setLoggedInUser
        };
    },
    {
        persist: true
    }
);
