// Vue imports
import { createApp } from 'vue';

// Vue router
import router from '@/router';

// App component
import App from '@/App.vue';

// Vuex store imports
import emitter from '@/eventBus';

// i18n imports
import i18n from '@/plugins/i18n';

// Composable imports
import clickOutside from '@/composables/clickOutside';

// Pinia store imports
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

// CSS imports
import '@/assets/css/tailwind.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/assets/css/vue-calendar.css';
import '@/assets/css/global.css';
import '@/assets/css/vueflow.css';
import '@/assets/filepond/filepond/filepond.css';
import '@/assets/filepond/filepond/filepond-plugin-file-poster.css';
import '@/assets/filepond/pintura/pintura.css';
import 'floating-vue/dist/style.css';
import '@/assets/css/flag-icons.css';
import '@/assets/css/common.css';


// Tailwind theme customization
import theme from '@/theme.js';
import { generateClasses } from '@formkit/tailwindcss';

// Font awesome icons imports
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fat } from '@fortawesome/pro-thin-svg-icons';

// Library fontawesome
library.add(fas, far, fal, fad, fab, fat);

// Components imports
import stepNumber from '@/components/forms/StepNumber.js';

// Formkit imports
import { plugin, defaultConfig } from '@formkit/vue';

// Import createProPlugin helper and any desired inputs
import {
    createProPlugin,
    toggle,
    datepicker,
    repeater,
    dropdown,
    autocomplete,
    slider,
    taglist,
    colorpicker,
    mask,
} from '@formkit/pro';

import Vue3TouchEvents from 'vue3-touch-events';

// Filepond imports
import Filepond from '@/assets/filepond/filepond/filepond.js';
import FilePondPluginFilePoster from '@/assets/filepond/filepond/filepond-plugin-file-poster.js';
import FilePondPluginFileValidateType from '@/assets/filepond/filepond/filepond-plugin-file-validate-type.js';

// Jodit config
import 'jodit-pro/es2021/jodit.css'

import Popper from 'vue3-popper';
import Datepicker from '@vuepic/vue-datepicker';

import { focus } from '@/composables/customDirectives.js';
import rendered from '@/directives/rendered';
const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

// Register the emitter globally
app.config.globalProperties.$emitter = emitter;

// Create the Pro plugin with your Project Key and desired inputs
const proPlugin = createProPlugin('fk-90354aed95', {
    repeater,
    datepicker,
    toggle,
    dropdown,
    autocomplete,
    slider,
    taglist,
    colorpicker,
    mask
    // ... and any other Pro Inputs
});


// Register the custom directive globally
app.directive('focus', focus);
app.directive('rendered', rendered);
app.use(router);
app.use(
    plugin,
    defaultConfig({
        config: {
            classes: generateClasses(theme),
        },
        icons: {
            checkedIcon:
                '<svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.5762 0.648438C12.9316 0.976562 12.9316 1.55078 12.5762 1.87891L5.57617 8.87891C5.24805 9.23438 4.67383 9.23438 4.3457 8.87891L0.845703 5.37891C0.490234 5.05078 0.490234 4.47656 0.845703 4.14844C1.17383 3.79297 1.74805 3.79297 2.07617 4.14844L4.94727 7.01953L11.3457 0.648438C11.6738 0.292969 12.248 0.292969 12.5762 0.648438Z" fill="currentColor"/></svg>',
            radioCheckedIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><circle id="Ellipse_362" data-name="Ellipse 362" cx="6" cy="6" r="6" fill="currentColor"/></svg>',
            moveUpIcon:
                '<svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.30469 5.07422C8.1875 5.19141 8.03125 5.25 7.875 5.25C7.69922 5.25 7.54297 5.19141 7.42578 5.07422L5.375 3.02344V9C5.375 9.35156 5.08203 9.625 4.75 9.625C4.4375 9.625 4.125 9.35156 4.125 9V3.02344L2.05469 5.07422C1.82031 5.32812 1.41016 5.32812 1.17578 5.07422C0.921875 4.83984 0.921875 4.42969 1.17578 4.19531L4.30078 1.07031C4.53516 0.816406 4.94531 0.816406 5.17969 1.07031L8.30469 4.19531C8.55859 4.42969 8.55859 4.83984 8.30469 5.07422Z" fill="#BAC4CD"/></svg>',
            moveDownIcon:
                '<svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.30469 6.32422L5.17969 9.44922C5.0625 9.56641 4.90625 9.625 4.75 9.625C4.57422 9.625 4.41797 9.56641 4.30078 9.44922L1.17578 6.32422C0.921875 6.08984 0.921875 5.67969 1.17578 5.44531C1.41016 5.19141 1.82031 5.19141 2.05469 5.44531L4.125 7.49609V1.5C4.125 1.16797 4.39844 0.894531 4.73047 0.894531C5.04297 0.894531 5.375 1.16797 5.375 1.5V7.49609L7.42578 5.44531C7.66016 5.19141 8.07031 5.19141 8.30469 5.44531C8.55859 5.67969 8.55859 6.08984 8.30469 6.32422Z" fill="#BAC4CD"/></svg>',
            removeIcon:
                '<svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.25 3C20.625 3 21 3.375 21 3.75C21 4.17188 20.625 4.5 20.25 4.5H19.3594L18.1875 21.2344C18.0469 22.8281 16.7344 24 15.1875 24H5.76562C4.21875 24 2.90625 22.8281 2.76562 21.2344L1.59375 4.5H0.75C0.328125 4.5 0 4.17188 0 3.75C0 3.375 0.328125 3 0.75 3H5.20312L6.42188 1.07812C6.79688 0.421875 7.54688 0 8.29688 0H12.6562C13.4062 0 14.1562 0.421875 14.5312 1.07812L15.75 3H20.25ZM8.29688 1.5C8.0625 1.5 7.82812 1.64062 7.6875 1.875L6.9375 3H14.0156L13.2656 1.875C13.125 1.64062 12.8906 1.5 12.6562 1.5H8.29688ZM17.8594 4.5H3.09375L4.26562 21.1406C4.3125 21.8906 4.96875 22.5 5.76562 22.5H15.1875C15.9844 22.5 16.6406 21.8906 16.6875 21.1406L17.8594 4.5Z" fill="#BAC4CD"/></svg>',
            selectIcon:
                '<svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.375 5.375C5.19922 5.375 5.04297 5.31641 4.92578 5.19922L1.17578 1.44922C0.921875 1.21484 0.921875 0.804688 1.17578 0.570312C1.41016 0.316406 1.82031 0.316406 2.05469 0.570312L5.375 3.87109L8.67578 0.570312C8.91016 0.316406 9.32031 0.316406 9.55469 0.570312C9.80859 0.804688 9.80859 1.21484 9.55469 1.44922L5.80469 5.19922C5.6875 5.31641 5.53125 5.375 5.375 5.375Z" fill="#BAC4CD"/></svg>',
            emailIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="13.5" viewBox="0 0 18 13.5"><path id="Path_5319" data-name="Path 5319" d="M16.312-13.5H1.687A1.688,1.688,0,0,0,0-11.812V-1.687A1.688,1.688,0,0,0,1.687,0H16.312A1.688,1.688,0,0,0,18-1.687V-11.812A1.688,1.688,0,0,0,16.312-13.5ZM1.687-12.375H16.312a.564.564,0,0,1,.562.562v1.455c-.77.65-1.87,1.547-5.295,4.264C10.986-5.621,9.816-4.486,9-4.5c-.816.014-1.99-1.121-2.58-1.593C3-8.81,1.895-9.707,1.125-10.357v-1.455A.564.564,0,0,1,1.687-12.375ZM16.312-1.125H1.687a.564.564,0,0,1-.562-.562V-8.895c.8.657,2.067,1.673,4.595,3.681C6.441-4.637,7.713-3.368,9-3.375c1.28.011,2.542-1.248,3.28-1.839,2.528-2.007,3.793-3.023,4.595-3.681v7.207A.564.564,0,0,1,16.312-1.125Z" transform="translate(0 13.5)" fill="#a3a3a3"/></svg>',
            phoneIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path id="Path_5320" data-name="Path 5320" d="M17.149.9l-3.544.819a1.1,1.1,0,0,1-1.259-.636L10.712-2.729a1.1,1.1,0,0,1,.313-1.283l1.895-1.55a12.3,12.3,0,0,0-5.611-5.611L5.759-9.279a1.1,1.1,0,0,1-1.283.313L.664-10.6a1.1,1.1,0,0,1-.636-1.255L.844-15.4a1.1,1.1,0,0,1,1.072-.854A16.08,16.08,0,0,1,18-.166,1.092,1.092,0,0,1,17.149.9ZM1.937-15.125l-.809,3.5L4.9-10.006,7-12.566A13.585,13.585,0,0,1,14.312-5.25L11.753-3.158,13.37.618l3.5-.809A14.951,14.951,0,0,0,1.937-15.125Z" transform="translate(18 1.75) rotate(180)" fill="#a3a3a3"/></svg>',
            minusIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M432 256C432 264.8 424.8 272 416 272H32c-8.844 0-16-7.15-16-15.99C16 247.2 23.16 240 32 240h384C424.8 240 432 247.2 432 256z"/></svg>',
            crossIcon:
                '<svg width="7" height="10" viewBox="0 0 8 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.05469 5.32031C7.30859 5.55469 7.30859 5.96484 7.05469 6.19922C6.9375 6.31641 6.78125 6.375 6.625 6.375C6.44922 6.375 6.29297 6.31641 6.17578 6.19922L4.125 4.14844L2.05469 6.19922C1.9375 6.31641 1.78125 6.375 1.625 6.375C1.44922 6.375 1.29297 6.31641 1.17578 6.19922C0.921875 5.96484 0.921875 5.55469 1.17578 5.32031L3.22656 3.25L1.17578 1.19922C0.921875 0.964844 0.921875 0.554688 1.17578 0.320312C1.41016 0.0664062 1.82031 0.0664062 2.05469 0.320312L4.125 2.37109L6.17578 0.320312C6.41016 0.0664062 6.82031 0.0664062 7.05469 0.320312C7.30859 0.554688 7.30859 0.964844 7.05469 1.19922L5.00391 3.26953L7.05469 5.32031Z" fill="#BAC4CD"/></svg>',
            searchIcon:
                '<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z" fill="#BAC4CD"/></svg>',
            chatBotIcon:
                '<svg width="20" height="16" viewBox="0 0 640 512" data-v-40de5bf4=""><path class="" fill="#BAC4CD" d="M448 96h-112V16C336 7.156 328.8 0 320 0S304 7.156 304 16V96H192C138.1 96 96 138.1 96 192v224c0 53.02 42.98 96 96 96h256c53.02 0 96-42.98 96-96V192C544 138.1 501 96 448 96zM512 384h-16c-8.844 0-16 7.156-16 16s7.156 16 16 16H512c0 35.29-28.71 64-64 64H192c-35.29 0-64-28.71-64-64h16C152.8 416 160 408.8 160 400S152.8 384 144 384H128V192c0-35.29 28.71-64 64-64h256c35.29 0 64 28.71 64 64V384zM36.72 228.6C39.72 225.6 43.66 224 47.84 224C56.66 224 63.97 216.9 64 208C64.03 199.2 56.88 192 48.03 192C35.31 192 23.19 196.9 14.16 205.9C5.031 215 0 227.1 0 240v127.1C0 394.5 21.47 416 47.88 416c8.844 0 16.06-7.156 16.06-16s-7.156-16-16-16C39.19 384 32 376.8 32 367.1V240C32 235.7 33.69 231.6 36.72 228.6zM592.1 192c-8.844 0-16 7.156-16 16S583.3 224 592.1 224C600.9 224 608 231.2 608 240v127.1c0 4.328-1.688 8.375-4.719 11.41C600.3 382.4 596.3 384 592.1 384C583.3 384 576 391.1 576 399.1C575.1 408.8 583.1 415.1 591.9 416c12.72 0 24.88-4.938 33.91-13.94C634.1 392.1 640 380.9 640 367.1V240C640 213.5 618.5 192 592.1 192zM240 384h-32C199.2 384 192 391.2 192 400S199.2 416 208 416h32C248.8 416 256 408.8 256 400S248.8 384 240 384zM336 384h-32C295.2 384 288 391.2 288 400S295.2 416 304 416h32c8.844 0 16-7.156 16-16S344.8 384 336 384zM432 384h-32c-8.844 0-16 7.156-16 16s7.156 16 16 16h32c8.844 0 16-7.156 16-16S440.8 384 432 384zM224 192C188.7 192 160 220.7 160 256s28.72 64 64 64s64-28.7 64-64S259.3 192 224 192zM224 288C206.3 288 192 273.6 192 256s14.34-32 32-32s32 14.36 32 32S241.7 288 224 288zM416 192c-35.28 0-64 28.7-64 64s28.72 64 64 64s64-28.7 64-64S451.3 192 416 192zM416 288c-17.66 0-32-14.36-32-32s14.34-32 32-32s32 14.36 32 32S433.7 288 416 288z"></path></svg>',
            lockIcon:
                '<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.125 6V4.25C3.125 2.33594 4.68359 0.75 6.625 0.75C8.53906 0.75 10.125 2.33594 10.125 4.25V6H11C11.957 6 12.75 6.79297 12.75 7.75V13C12.75 13.9844 11.957 14.75 11 14.75H2.25C1.26562 14.75 0.5 13.9844 0.5 13V7.75C0.5 6.79297 1.26562 6 2.25 6H3.125ZM4.4375 6H8.8125V4.25C8.8125 3.04688 7.82812 2.0625 6.625 2.0625C5.39453 2.0625 4.4375 3.04688 4.4375 4.25V6ZM1.8125 13C1.8125 13.2461 2.00391 13.4375 2.25 13.4375H11C11.2188 13.4375 11.4375 13.2461 11.4375 13V7.75C11.4375 7.53125 11.2188 7.3125 11 7.3125H2.25C2.00391 7.3125 1.8125 7.53125 1.8125 7.75V13Z" fill="black"/></svg>',
            lockIconSolid:
            '<svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.3125 3.0625V4H6.4375V3.0625C6.4375 2.20312 5.73438 1.5 4.875 1.5C3.99609 1.5 3.3125 2.20312 3.3125 3.0625ZM2.0625 4V3.0625C2.0625 1.51953 3.3125 0.25 4.875 0.25C6.41797 0.25 7.6875 1.51953 7.6875 3.0625V4H8C8.68359 4 9.25 4.56641 9.25 5.25V9C9.25 9.70312 8.68359 10.25 8 10.25H1.75C1.04688 10.25 0.5 9.70312 0.5 9V5.25C0.5 4.56641 1.04688 4 1.75 4H2.0625Z" fill="#1A1A1A"/></svg>',
                refreshIcon:
                '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.4141 7.65234C12.0586 7.54297 11.7031 7.73438 11.5938 8.08984C11.0195 10.1406 9.10547 11.5625 6.97266 11.5625C5.14062 11.5625 3.5 10.5234 2.67969 8.9375H5.44141C5.79688 8.9375 6.09766 8.66406 6.09766 8.28125C6.09766 7.92578 5.82422 7.625 5.44141 7.625H1.06641C0.710938 7.625 0.410156 7.92578 0.410156 8.28125V12.6562C0.410156 13.0391 0.710938 13.3125 1.06641 13.3125C1.44922 13.3125 1.72266 13.0391 1.72266 12.6562V9.86719C2.81641 11.6992 4.8125 12.875 6.97266 12.875C9.67969 12.875 12.0859 11.0703 12.8516 8.47266C12.9609 8.11719 12.7695 7.76172 12.4141 7.65234ZM12.9062 0.1875C12.5234 0.1875 12.25 0.488281 12.25 0.84375V3.66016C11.1562 1.82812 9.16016 0.625 7 0.625C4.29297 0.625 1.85938 2.45703 1.09375 5.05469C1.01172 5.38281 1.20312 5.76562 1.55859 5.84766C1.91406 5.95703 2.26953 5.76562 2.35156 5.41016C2.95312 3.38672 4.86719 1.9375 7 1.9375C8.80469 1.9375 10.4453 3.00391 11.2656 4.5625H8.53125C8.14844 4.5625 7.875 4.86328 7.875 5.21875C7.875 5.60156 8.14844 5.875 8.53125 5.875H12.9062C13.2617 5.875 13.5625 5.60156 13.5625 5.21875V0.84375C13.5625 0.488281 13.2617 0.1875 12.9062 0.1875Z" fill="#BAC4CD"/></svg>',
            poundIcon:
                '<svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 6H8.25C8.65625 6 9 6.34375 9 6.75C9 7.1875 8.65625 7.5 8.25 7.5H4V9.5C4 10.2812 3.78125 11.0625 3.40625 11.7812L3 12.5H10.25C10.6562 12.5 11 12.8438 11 13.25C11 13.6875 10.6562 14 10.25 14H1.75C1.46875 14 1.21875 13.875 1.09375 13.6562C0.96875 13.4062 0.9375 13.125 1.0625 12.9062L2.09375 11.0625C2.34375 10.5625 2.5 10.0312 2.5 9.5V7.5H1.75C1.3125 7.5 1 7.1875 1 6.75C1 6.34375 1.3125 6 1.75 6H2.5V4C2.5 1.78125 4.25 0 6.46875 0C6.875 0 7.3125 0.09375 7.71875 0.21875L10.2188 1.0625C10.625 1.1875 10.8125 1.625 10.6875 2C10.5625 2.40625 10.125 2.59375 9.75 2.46875L7.25 1.65625C7 1.5625 6.71875 1.5 6.46875 1.5C5.09375 1.5 4 2.625 4 4V6Z" fill="#BAC4CD"/></svg>',
            plusIcon:'<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.75 1.25V6.25H12.75C13.1562 6.25 13.5 6.59375 13.5 7C13.5 7.4375 13.1562 7.75 12.75 7.75H7.75V12.75C7.75 13.1875 7.40625 13.5 7 13.5C6.5625 13.5 6.25 13.1875 6.25 12.75V7.75H1.25C0.8125 7.75 0.5 7.4375 0.5 7C0.5 6.59375 0.8125 6.25 1.25 6.25H6.25V1.25C6.25 0.84375 6.5625 0.5 7 0.5C7.40625 0.5 7.75 0.84375 7.75 1.25Z" fill="#00B0FC"/></svg>',
            ...FontAwesomeIcon,
            calendarIcon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" fill="#BAC4CD"/></svg>',
            globeIcon: '<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.5 15C8.71875 15 9.34375 14.7812 9.96875 13.5C10.25 12.9375 10.5 12.2812 10.6562 11.5H6.3125C6.5 12.2812 6.71875 12.9375 7 13.5C7.65625 14.7812 8.25 15 8.5 15ZM6.0625 10H10.9062C10.9688 9.53125 11 9.03125 11 8.5C11 8 10.9688 7.5 10.9062 7H6.0625C6 7.5 6 8 6 8.5C6 9.03125 6 9.53125 6.0625 10ZM6.3125 5.5H10.6562C10.5 4.75 10.25 4.09375 9.96875 3.53125C9.34375 2.25 8.71875 2 8.5 2C8.25 2 7.65625 2.25 7 3.53125C6.71875 4.09375 6.5 4.75 6.3125 5.5ZM12.4062 7C12.4688 7.5 12.4688 8 12.4688 8.5C12.4688 9.03125 12.4688 9.53125 12.4062 10H14.8125C14.9375 9.53125 15 9.03125 15 8.5C15 8 14.9375 7.5 14.8125 7H12.4062ZM14.25 5.5C13.5938 4.21875 12.5 3.1875 11.1875 2.59375C11.625 3.40625 11.9688 4.40625 12.1875 5.5H14.25ZM4.78125 5.5C5 4.40625 5.34375 3.40625 5.78125 2.59375C4.46875 3.1875 3.375 4.21875 2.71875 5.5H4.78125ZM2.15625 7C2.0625 7.5 2 8 2 8.5C2 9.03125 2.03125 9.53125 2.15625 10H4.5625C4.5 9.53125 4.5 9.03125 4.5 8.5C4.5 8 4.5 7.5 4.5625 7H2.15625ZM11.1875 14.4375C12.5 13.8438 13.5938 12.8125 14.25 11.5H12.1875C11.9688 12.625 11.625 13.625 11.1875 14.4375ZM5.78125 14.4375C5.34375 13.625 5 12.625 4.78125 11.5H2.71875C3.375 12.8125 4.46875 13.8438 5.78125 14.4375ZM8.5 16.5C5.625 16.5 3 15 1.5625 12.5C0.125 10.0312 0.125 7 1.5625 4.5C3 2.03125 5.625 0.5 8.5 0.5C11.3438 0.5 13.9688 2.03125 15.4062 4.5C16.8438 7 16.8438 10.0312 15.4062 12.5C13.9688 15 11.3438 16.5 8.5 16.5Z" fill="#BAC4CD"/></svg>',
            leftAngle: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M203.9 405.3c5.877 6.594 5.361 16.69-1.188 22.62c-6.562 5.906-16.69 5.375-22.59-1.188L36.1 266.7c-5.469-6.125-5.469-15.31 0-21.44l144-159.1c5.906-6.562 16.03-7.094 22.59-1.188c6.918 6.271 6.783 16.39 1.188 22.62L69.53 256L203.9 405.3z"/></svg>',
            rightAngle: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M219.9 266.7L75.89 426.7c-5.906 6.562-16.03 7.094-22.59 1.188c-6.918-6.271-6.783-16.39-1.188-22.62L186.5 256L52.11 106.7C46.23 100.1 46.75 90.04 53.29 84.1C59.86 78.2 69.98 78.73 75.89 85.29l144 159.1C225.4 251.4 225.4 260.6 219.9 266.7z"/></svg>',
            reloadIcon: '<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1.09375 5.875C0.710938 5.875 0.4375 5.60156 0.4375 5.21875V1.28125C0.4375 0.925781 0.710938 0.625 1.09375 0.625C1.44922 0.625 1.75 0.925781 1.75 1.28125V3.49609L2.29688 2.83984C3.41797 1.5 5.08594 0.625 7 0.625C10.3633 0.625 13.125 3.38672 13.125 6.75C13.125 10.1406 10.3633 12.875 7 12.875C5.60547 12.875 4.34766 12.4375 3.30859 11.6719C3.03516 11.4531 2.95312 11.043 3.17188 10.7422C3.39062 10.4414 3.80078 10.3867 4.10156 10.6055C4.89453 11.207 5.90625 11.5625 7 11.5625C9.65234 11.5625 11.8125 9.42969 11.8125 6.75C11.8125 4.09766 9.65234 1.9375 7 1.9375C5.49609 1.9375 4.18359 2.62109 3.28125 3.6875L2.54297 4.5625H5.03125C5.38672 4.5625 5.6875 4.86328 5.6875 5.21875C5.6875 5.60156 5.38672 5.875 5.03125 5.875H1.09375Z" fill="#2B2C30"/></svg>',
            copyIcon: '<svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M10.5 9.9375C10.7188 9.9375 10.9375 9.74609 10.9375 9.5V3.92188L9.07812 2.0625H5.25C5.00391 2.0625 4.8125 2.28125 4.8125 2.5V9.5C4.8125 9.74609 5.00391 9.9375 5.25 9.9375H10.5ZM5.25 11.25C4.26562 11.25 3.5 10.4844 3.5 9.5V2.5C3.5 1.54297 4.26562 0.75 5.25 0.75H9.07812C9.40625 0.75 9.76172 0.914062 10.0078 1.16016L11.8398 2.99219C12.0859 3.23828 12.25 3.59375 12.25 3.92188V9.5C12.25 10.4844 11.457 11.25 10.5 11.25H5.25ZM1.75 4.25H2.625V5.5625H1.75C1.50391 5.5625 1.3125 5.78125 1.3125 6V13C1.3125 13.2461 1.50391 13.4375 1.75 13.4375H7C7.21875 13.4375 7.4375 13.2461 7.4375 13V12.125H8.75V13C8.75 13.9844 7.95703 14.75 7 14.75H1.75C0.765625 14.75 0 13.9844 0 13V6C0 5.04297 0.765625 4.25 1.75 4.25Z" fill="#2B2C30"/></svg>',
            dragIcon:'<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.125 13.625V11.375H16.875V13.625H19.125ZM21 13.625C21 14.7031 20.1562 15.5 19.125 15.5H16.875C15.7969 15.5 15 14.7031 15 13.625V11.375C15 10.3438 15.7969 9.5 16.875 9.5H19.125C20.1562 9.5 21 10.3438 21 11.375V13.625ZM19.125 4.625V2.375H16.875V4.625H19.125ZM21 4.625C21 5.70312 20.1562 6.5 19.125 6.5H16.875C15.7969 6.5 15 5.70312 15 4.625V2.375C15 1.34375 15.7969 0.5 16.875 0.5H19.125C20.1562 0.5 21 1.34375 21 2.375V4.625ZM9.375 13.625H11.625V11.375H9.375V13.625ZM11.625 15.5H9.375C8.29688 15.5 7.5 14.7031 7.5 13.625V11.375C7.5 10.3438 8.29688 9.5 9.375 9.5H11.625C12.6562 9.5 13.5 10.3438 13.5 11.375V13.625C13.5 14.7031 12.6562 15.5 11.625 15.5ZM11.625 4.625V2.375H9.375V4.625H11.625ZM13.5 4.625C13.5 5.70312 12.6562 6.5 11.625 6.5H9.375C8.29688 6.5 7.5 5.70312 7.5 4.625V2.375C7.5 1.34375 8.29688 0.5 9.375 0.5H11.625C12.6562 0.5 13.5 1.34375 13.5 2.375V4.625ZM1.875 13.625H4.125V11.375H1.875V13.625ZM4.125 15.5H1.875C0.796875 15.5 0 14.7031 0 13.625V11.375C0 10.3438 0.796875 9.5 1.875 9.5H4.125C5.15625 9.5 6 10.3438 6 11.375V13.625C6 14.7031 5.15625 15.5 4.125 15.5ZM4.125 4.625V2.375H1.875V4.625H4.125ZM6 4.625C6 5.70312 5.15625 6.5 4.125 6.5H1.875C0.796875 6.5 0 5.70312 0 4.625V2.375C0 1.34375 0.796875 0.5 1.875 0.5H4.125C5.15625 0.5 6 1.34375 6 2.375V4.625Z" fill="#DDE3E4"/></svg>',
            dragIconVertical: '<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 3C1.15625 3 0.5 2.34375 0.5 1.5C0.5 0.703125 1.15625 0 2 0C2.79688 0 3.5 0.703125 3.5 1.5C3.5 2.34375 2.79688 3 2 3ZM2 10.5C1.15625 10.5 0.5 9.84375 0.5 9C0.5 8.20312 1.15625 7.5 2 7.5C2.79688 7.5 3.5 8.20312 3.5 9C3.5 9.84375 2.79688 10.5 2 10.5ZM3.5 16.5C3.5 17.3438 2.79688 18 2 18C1.15625 18 0.5 17.3438 0.5 16.5C0.5 15.7031 1.15625 15 2 15C2.79688 15 3.5 15.7031 3.5 16.5ZM8 3C7.15625 3 6.5 2.34375 6.5 1.5C6.5 0.703125 7.15625 0 8 0C8.79688 0 9.5 0.703125 9.5 1.5C9.5 2.34375 8.79688 3 8 3ZM9.5 9C9.5 9.84375 8.79688 10.5 8 10.5C7.15625 10.5 6.5 9.84375 6.5 9C6.5 8.20312 7.15625 7.5 8 7.5C8.79688 7.5 9.5 8.20312 9.5 9ZM8 18C7.15625 18 6.5 17.3438 6.5 16.5C6.5 15.7031 7.15625 15 8 15C8.79688 15 9.5 15.7031 9.5 16.5C9.5 17.3438 8.79688 18 8 18Z" fill="#BAC4CD"/></svg>',
            percentIcon:'<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5312 1.53125L1.5 11.5312C1.21875 11.8438 0.75 11.8438 0.46875 11.5312C0.15625 11.25 0.15625 10.7812 0.46875 10.4688L10.4688 0.5C10.75 0.1875 11.2188 0.1875 11.5312 0.5C11.8125 0.78125 11.8125 1.25 11.5312 1.53125ZM3.5 2C3.5 2.5625 3.1875 3.03125 2.75 3.3125C2.28125 3.59375 1.6875 3.59375 1.25 3.3125C0.78125 3.03125 0.5 2.5625 0.5 2C0.5 1.46875 0.78125 1 1.25 0.71875C1.6875 0.4375 2.28125 0.4375 2.75 0.71875C3.1875 1 3.5 1.46875 3.5 2ZM11.5 10C11.5 10.5625 11.1875 11.0312 10.75 11.3125C10.2812 11.5938 9.6875 11.5938 9.25 11.3125C8.78125 11.0312 8.5 10.5625 8.5 10C8.5 9.46875 8.78125 9 9.25 8.71875C9.6875 8.4375 10.2812 8.4375 10.75 8.71875C11.1875 9 11.5 9.46875 11.5 10Z" fill="#BAC4CD"/></svg>',
            browserIcon:'<svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 1.8125C0.03125 1.53125 0.09375 1.28125 0.21875 1.0625C0.40625 0.75 0.65625 0.46875 1 0.28125C1.21875 0.15625 1.5 0.0625 1.78125 0.03125C1.84375 0.03125 1.90625 0 2 0H14C15.0938 0 16 0.90625 16 2V4V12C16 13.125 15.0938 14 14 14H2C0.875 14 0 13.125 0 12V4V2C0 1.9375 0 1.875 0 1.8125ZM1.5 4V12C1.5 12.2812 1.71875 12.5 2 12.5H14C14.25 12.5 14.5 12.2812 14.5 12V4H1.5Z" fill="#BAC4CD"/></svg>',
    },
        inputs: {
        stepNumber,
    },
        plugins: [proPlugin],
    })
);

// FloatingVue imports
import FloatingVue from "floating-vue";

app.use(FloatingVue, {
    themes: {
        "notificationMenu": {
            $extend: "dropdown"
            // Other options (see the 'Global options' section)
        },
        "autocompleteMenu": {
            $extend: "dropdown"
            // Other options (see the 'Global options' section)
        },
        "profileMenu": {
            $extend: "dropdown"
            // Other options (see the 'Global options' section)
        },
        "toolTipMenu": {
            $extend: "tooltip"
            // Other options (see the 'Global options' section)
        },
        "calendarMenu": {
            $extend: "dropdown"
            // Other options (see the 'Global options' section)
        },
        "activityMenu": {
            $extend: "dropdown"
            // Other options (see the 'Global options' section)
        },
        "appDropdownList": {
            $extend: "dropdown"
        },
        "appColorSelect": {
            $extend: "dropdown"
        },
        "messageMenu":{
            $extend:'dropdown'
        },
        "groupMenu":{
            $extend:'dropdown'
        },
        "tenancyYearMenu":{
            $extend:'dropdown'
        },
        "emojiMenu": {
            $extend: 'dropdown',
            // Other options (see the 'Global options' section)
        },
        "stepperDropdown": {
            $extend: 'dropdown',
            // Other options (see the 'Global options' section)
        },
        "diaryDropdown": {
            $extend: 'dropdown',
            // Other options (see the 'Global options' section)
        },
    }
});

// Ably requires laravel-echo and pusher-js for notifications
import Echo from "laravel-echo";
import Pusher from "pusher-js";

Pusher.logToConsole = false;
window.Echo = new Echo({
    broadcaster: "pusher",
    key: "zS3hGQ.G-GCAg",
    wsHost: "realtime-pusher.ably.io",
    wsPort: 443,
    cluster: "",
    // disableStats: true,
    //authEndpoint: "http://pms6-api.voza.io/broadcasting/auth",
    // auth: {
    //     headers: {
    //         Accept: 'application/json',
    //         Authorization: 'Bearer ' + token,
    //     },
    // },
});

app.use(Filepond);
app.use(FilePondPluginFilePoster);
app.use(FilePondPluginFileValidateType);

app.use(i18n);

app.use(pinia);
app.use(Vue3TouchEvents);

// eslint-disable-next-line vue/multi-word-component-names
app.component('fa', FontAwesomeIcon);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Popper', Popper);
// eslint-disable-next-line vue/multi-word-component-names
app.component('Datepicker', Datepicker);
app.directive('click-outside', clickOutside);
app.directive("visible", function (el, binding) {
    // eslint-disable-next-line no-extra-boolean-cast
    el.style.visibility = !!binding.value ? "visible" : "hidden";
});
app.mount('#app');
export default app;
